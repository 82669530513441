export const dummyVM: VendingMachine = {
  address: "",
  postalcode: "",
  aesKey: "",
  city: "",
  description: "",
  identifier: { text: "", symbol: "", color: "" },
  location: { latitude: undefined, longitude: undefined },
  merchant: {
    a2aIban:"",
    a2aAccountHolder: "",
    mid: "",
    blowfishKey: "",
    country: "",
    address: "",
    city: "",
    postcode: "",
    vatId: "",
    currency: "",
    customerId: "",
    hmacKeyComputop: "",
    name: "",
    firstfree: false,
    freelimit: 0,
    networks: [],
    permissions: [],
    vendingMachineTypes: [],
    tiptoggle: false,
    purposetoggle: false,
    purpose: "",
    ageVerificationPOSSelection: [],
    twint_id: "",
    authenticationToken: "",
    hmacKeyThirdParty: "",
    timezone: "",
    dimoco_merchant_id: "",
    dimoco_password: ""
  },
  serialNumber: "",
  vendingMachineType: { type: "", description: "" },
  vmName: "",
  unlocked: false,
  ageVerification: 0,
  compartment: [],
  terminalId: [],
  orderId: "",
};
